import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que recorda al coral d’uns 3 a 9 cm d’altura, de coloració variable, de blanquinós brut a grisenc amb tons més foscos quan està un poc assecat, format per branques dretes, cilíndriques o esclafades de forma rodona o dentada que surten d’un tronc comú. Aquestes branques poden acabar de forma rodona o dentada i recorregudes per una mena de solcs. Les espores són blanques en massa, arrodonides, hialines, llises, de 7-11 x 7-10 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      